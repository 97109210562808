<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-row>
          <!-- <b-col cols="12" class="mb-2">
                      <h4>Pengajuan Pengeluaran Riil</h4>
                      <p class="font-italic">
                        Untuk pengajuan pengeluaran riil, silahkan siapkan kumpulan kwitansi
                      </p>
                    </b-col> -->
          <b-col cols="12" md="6" class="mb-2">
            <h6>No SPPD</h6>
            <p class="card-text">
              {{ currentSppd.sppd_number || '-' }}
            </p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>{{ employee.type === 'pegawai' ? 'NIPPOS' : 'NIK' }} / Nama</h6>
            <p class="card-text">
              {{ employee.type === 'pegawai' ? employee.nippos : employee.nik }}
              /
              {{ employee.type === 'pegawai' ? employee.nama : employee.name }}
            </p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Jenis Fasilitas</h6>
            <p class="card-text">
              {{ sppdFacilityType }}
            </p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Jabatan</h6>
            <p class="card-text">
              {{
                employee.type === 'pegawai'
                  ? employee.descjabatan
                  : employee.position
              }}
            </p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Bagian/Unit Kerja</h6>
            <p class="card-text">
              {{ employee.namabagian || '-' }}
            </p>
          </b-col>
          <b-col
            cols="12"
            md="6"
            v-if="currentSppd.real_expense_step"
            class="mb-2"
          >
            <h6>Status</h6>
            <p class="card-text">
              {{ currentSppd.status_real_expense_detail || '-' }}
            </p>
          </b-col>
          <b-col cols="12" class="pl-2 mb-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              class="mr-1 float-right"
              variant="outline-primary"
              @click="printOut()"
            >
              <feather-icon icon="PrinterIcon" class="mr-25" />
              <span>Cetak</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-table responsive="sm" :fields="fields" :items="items">
              <template #cell(no)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(cost_component)="data">
                {{ data.item.cost_component.name }}
              </template>
              <template #cell(total_cost)="data">
                {{ data.item.total_cost | currency }}
              </template>
              <template #cell(note)="data">
                <div style="max-width: 100px">
                  {{ data.item.note }}
                </div>
              </template>
              <template #cell(receipt)="data">
                <span v-if="data.item.receipt != ''">
                  <b-link
                    class="btn btn-info"
                    target="_blank"
                    :href="data.item.file"
                  >
                    Cek Kwitansi
                  </b-link>
                </span>
                <span v-else> Belum ada kwitansi </span>
              </template>
              <template #cell(status)="data">
                <div style="max-width: 80px">
                  <template v-if="data.item.status == 1">Disetujui</template>
                  <template v-else-if="data.item.status == 0">Ditolak</template>
                  <template v-else>Menunggu Approval</template>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row
          v-show="model.status_real_expense_detail == 'Menunggu Dibayarkan'"
        >
          <b-col cols="6" class="pl-2 mb-2">
            <h6 class="mb-25">Approval Admin</h6>
            <p class="card-text">Rp1.250.000</p>
          </b-col>
          <b-col cols="6" class="pl-2 mb-2">
            <h6 class="mb-25">Approval Manajer Ruta</h6>
            <p class="card-text">203232 / {{ model.name }}</p>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="3" class="ml-5">
            <h6>Total Disetujui :</h6>
          </b-col>
          <b-col cols="6">
            {{ total_biaya_approved | currency }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3" class="ml-5">
            <h6>Total Keseluruhan :</h6>
          </b-col>
          <b-col cols="6">
            {{ total_biaya | currency }}
          </b-col>
        </b-row>
        <b-card-footer>
          <!-- back -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1 float-right"
            variant="outline-secondary"
            :to="{ name: 'pengeluaran-rill' }"
          >
            Kembali
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BLink,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BLink,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BTable,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  props: {
    sppd: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const sppdId = this.$route.params.id
    return {
      sppdId,
      currentSppd: this.sppd,
      name: 'Pengajuan Rill',
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: '/real-expenses',
        redirect: 'pengeluaran-rill',
      },
      model: {},
      fields: [
        'no',
        { key: 'cost_component', label: 'Komponen Biaya' },
        { key: 'total_cost', label: 'Biaya' },
        { key: 'note', label: 'Catatan' },
        { key: 'receipt', label: 'Kwitansi' },
        { key: 'status', label: 'Status' },
      ],
      items: [],
      data: [],
      kwitansi: null,
      messages: '',
      status: '',
      status_approved: [],
    }
  },
  computed: {
    sendDisabledState() {
      if (!this.selectedTravelAgent) {
        return this.items.length === 0 || !this.kwitansi
      }
      return false
    },
    sppdFacilityType() {
      if (this.currentSppd.sppd_facility_type_id == 1) {
        return 'Perjalanan Dinas Biasa'
      }
      if (this.currentSppd.sppd_facility_type_id == 2) {
        return 'Perjalanan Dinas Fasilitas Khusus'
      }
      return '-'
    },
    employee() {
      return this.currentSppd.employee?.raw
        ? JSON.parse(this.currentSppd.employee.raw)
        : {}
    },
    total_biaya() {
      return this.items.reduce((acc, item) => acc + item.total_cost, 0)
    },
    total_biaya_approved() {
      return this.status_approved.reduce(
        (acc, item) => acc + item.total_cost,
        0,
      )
    },
  },
  mounted() {
    let _ = this
    _.getData()
  },
  methods: {
    getData() {
      let _ = this
      axios.get(_.config.api + '/' + this.$route.params.id).then(response => {
        let _ = this
        _.currentSppd = response.data.data
        _.items = response.data.data.real_expenses
        _.status = _.items.filter(e => e.status === 1)
        if (_.status.length > 0) {
          _.status_approved = _.status
        }
      })
    },
    printOut() {
      let _ = this
      axios({
        url: _.config.api + '/' + this.$route.params.id + '/print',
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute(
          'download',
          'pengajuan-rill-' + _.currentSppd.sppd_number + '.pdf',
        )
        document.body.appendChild(fileLink)

        fileLink.click()
      })
    },
  },
}
</script>
